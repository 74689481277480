// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["M3rlZ_rrY"];

const serializationHash = "framer-Kj23D"

const variantClassNames = {M3rlZ_rrY: "framer-v-1tdhixz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition2 = {delay: 0, duration: 2, ease: [0, 0, 1, 1], type: "tween"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 24}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, DIzkj79d7: image ?? props.DIzkj79d7 ?? {src: "https://framerusercontent.com/images/Pl6V0VsJNz27Sc5dcIRmn66Qcs.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/Pl6V0VsJNz27Sc5dcIRmn66Qcs.png?scale-down-to=512 512w,https://framerusercontent.com/images/Pl6V0VsJNz27Sc5dcIRmn66Qcs.png 800w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DIzkj79d7, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "M3rlZ_rrY", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1tdhixz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"M3rlZ_rrY"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ImageWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"mirror"} __framer__loopTransition={transition2} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 1024, intrinsicWidth: 1024, pixelHeight: 1024, pixelWidth: 1024, sizes: "240px", ...toResponsiveImage(DIzkj79d7)}} className={"framer-uo7w3m"} data-framer-name={"3D Element"} layoutDependency={layoutDependency} layoutId={"QP8hHOySI"} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Kj23D.framer-dbyvrk, .framer-Kj23D .framer-dbyvrk { display: block; }", ".framer-Kj23D.framer-1tdhixz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 240px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 240px; }", ".framer-Kj23D .framer-uo7w3m { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 240px); left: 50%; overflow: visible; position: absolute; top: 50%; width: 240px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Kj23D.framer-1tdhixz { gap: 0px; } .framer-Kj23D.framer-1tdhixz > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Kj23D.framer-1tdhixz > :first-child { margin-left: 0px; } .framer-Kj23D.framer-1tdhixz > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 240
 * @framerIntrinsicWidth 240
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DIzkj79d7":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRjmV1LbN3: React.ComponentType<Props> = withCSS(Component, css, "framer-Kj23D") as typeof Component;
export default FramerRjmV1LbN3;

FramerRjmV1LbN3.displayName = "3D-element";

FramerRjmV1LbN3.defaultProps = {height: 240, width: 240};

addPropertyControls(FramerRjmV1LbN3, {DIzkj79d7: {__defaultAssetReference: "data:framer/asset-reference,Pl6V0VsJNz27Sc5dcIRmn66Qcs.png?originalFilename=Removal-479.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerRjmV1LbN3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})